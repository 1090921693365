
.AvatarControlPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.AvatarHighlight {
  background-color: rgb(188, 185, 236);
}

.AvatarIcon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.AvatarFileInput {
  display: none;
}

.AvatarDropzone {
  min-width: 230px;
  max-width: 260px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.AvatarButtonPanel {
  display: flex;
  gap: 5px;
}
.AvatarButtonPanel button {
  height: 31px;
  background: transparent;
  color: #747f8d;
  border: 1px solid #d3d7db;
  border-radius: 1em;
  padding: 0 20px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .AvatarDropzone {
    align-items: normal;
  }
  .AvatarButtonPanel button:first-child {
    margin-bottom: 16px;
  }
  .AvatarButtonPanel button {
width: 100%;
    font-size: 0.3em;
}
}