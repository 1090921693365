
.statistics-panel h5 {
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.statistics-panel table {
  width: 100%;
  display: table;
}
.statistics-paneltbody tr td {
  text-transform: uppercase;
  padding: 0.5rem;
}
.statistics-panel .label {
  text-transform: uppercase;
  font-weight: bold;
  color: #e9e9e9;
  width: 40%;
  font-size: 0.75em;
  white-space: nowrap;
}
.statistics-panel .value {
    color: #ff8500;
    font-size: 1.1em;
    white-space: nowrap;
}
/* Outer container */
.custom-progress-bar-outer {
  width: 300px;              /* Make the bar wider */
  height: 20px;              /* Taller for easier readability */
  background: #3f3f3f;       /* A dark grey background for unfilled portion */
  border-radius: 10px;       /* Smooth corners */
  position: relative;
  margin: 0 auto;            /* Center horizontally (optional) */
  overflow: hidden;          /* Hide overflow of the filled portion's corners */
}

/* Filled portion */
.custom-progress-bar-filled {
  height: 100%;
  background: linear-gradient(90deg, #cc00ff, #ff00cc); 
  /* or any gradient you like */
  transition: width 0.4s ease;
}

/* Single label, absolutely centered */
.custom-progress-bar-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 0.85rem;
  font-weight: bold;
  pointer-events: none; /* So clicks pass through */
  z-index: 2;
}

/* Ranks below the bar, spaced out */
.custom-rank-row {
  display: flex;
  justify-content: space-between;
  width: 300px; /* match bar width */
  margin: 4px auto 0; /* small top margin, auto to center horizontally */
  font-weight: bold;
  color: #ff8500; /* or your chosen color */
  font-size: 0.9rem;
}

/* Example left vs. right styling (optional) */
.custom-rank-left, .custom-rank-right {
  min-width: 20px;
  margin-top: -20px;
  text-align: center;
  z-index: 1;
}

.rank-indicators {
  display: flex;
  justify-content: space-between;
}
.rank-indicators div{
  margin-top: -5px !important;
 padding: 4px;
 border-radius: 50%;
 box-shadow:  0 0 4px #f9f9f972;
}

.progress-label-left {
  left: 5px !important;
}

.progress-label-right {
  right: 5px !important;
}
.rank-indicators .rank-minus-one {
  transform: translateX(-20px) !important;
  
}
.rank-indicators .rank-plus-one {
  transform: translateX(20px) !important;

}

.progress-bar-filled {
  position: relative;
  z-index: 2;
  height: 100%;
  background: linear-gradient(#e1ff00, #fdb500 ) !important;
}

.progress-bar-filled::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0px;
  height: 0;
  border-style: solid;
  border-width: 0px 2px 20px 4px;
  border-color: #fb03ff98;
  z-index: 3;
}


.progress-bar-remaining {
  height: 100%;
  background: linear-gradient(#af02ff, #f01fff) !important;
  position: relative;
  z-index: 1;
  float: right;
  margin-top: -10px !important;
}


.statistics-panel {
  margin-bottom: 20px;
}


.room-link {
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.5);
  margin: auto !important;
  transform: translateY(-200%);
  display: block !important;
}

.statistics-panel .loading {
  text-align: center;
  font-style: italic;
  color: gray;
}

@keyframes slideIn {
  0% {
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(0);
  }
}

.statistics-panel {
  margin: 30px 0;
}
.statistics-panel .filters {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.statistics-panel div {
  margin: 5px;
}


.apexcharts-tooltip {
  padding: 10px;
}

.step-10 .apexcharts-xaxis-label,
.step-5 .apexcharts-xaxis-label {
  display: none;
}

.step-10 .apexcharts-xaxis-label:nth-child(10n) {
  display: revert;
}

.step-5 .apexcharts-xaxis-label:nth-child(5n) {
  display: revert;
}
